<template>
    <div class="device-index">
        <div class="container-header">
            <div class="top-actions-group">
                <div class="action-left">
                    <el-form class="geology-form">
                        <el-select v-model="listfilter.deviceid" filterable remote reserve-keyword default-first-option
                            clearable placeholder="请选择监测设备" size="medium" :remote-method="getDeviceList"
                            :loading="getDeviceLoading" @change="onSearch">
                            <el-option v-for="item in deviceSelect" :key="item.Id" :label="item.DeviceName"
                                :value="item.Id">
                            </el-option>
                        </el-select>

                        <el-date-picker v-model="filterDaterange" type="daterange" range-separator="-"
                            start-placeholder="开始日期" end-placeholder="结束日期" size="medium"
                            style="width: 240px; margin-left: 10px" value-format="yyyy-MM-dd" @change="dateChange">
                        </el-date-picker>
                        <el-button style="margin-left: 10px" type="primary" size="medium" icon="el-icon-search"
                            @click="onSearch">查询</el-button>
                    </el-form>
                </div>
                <div class="action-right">
                    <el-button type="primary" icon="el-icon-upload2" size="medium" @click="onAdd">上传固件</el-button>
                </div>
            </div>
        </div>

        <el-table class="list-table" ref="multipleTable" :data="listData" tooltip-effect="dark" style="width: 100%"
            :stripe="true" :border="false" v-loading="tableLoading">
            <el-table-column label="设备名称" prop="DeviceName"></el-table-column>
            <el-table-column label="设备IEMI" prop="DeviceIemi"></el-table-column>
            <el-table-column label="固件地址" prop="FileName"></el-table-column>
            <el-table-column label="固件版本" prop="Version"></el-table-column>
            <el-table-column label="发送状态" prop="IsSend">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.IsSend == true" effect="dark" size="small">已发送</el-tag>
                    <el-tag v-else type="danger" effect="dark" size="small">未发送</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="CreateTime" width="100">
                <template slot-scope="scope">
                    {{ scope.row.CreateTime | datetime }}
                </template>
            </el-table-column>
            <el-table-column label="操作" width="110">
                <template slot-scope="scope">
                    <el-button type="warning" icon="el-icon-error" size="small" style="margin-left: 10px"
                        @click="onListDel(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <pagination background layout="prev, pager, next" prev-text="上一页" next-text="下一页" class="list-pagination"
            :page-size="page.pagesize" hide-on-single-page :total="page.total" :current-page.sync="page.pageindex"
            @current-change="onPageChange">
        </pagination>

        <el-dialog title="上传固件" :visible.sync="dialogFormVisible" width="500px" custom-class="geology-dialog"
            :modal-append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false"
            :close-on-press-escape="false">
            <el-form ref="form" class="geology-form" :rules="formRules" :model="form" label-width="120px"
                style="padding-top: 20px">
                <el-form-item label="设备" prop="DeviceId">
                    <el-select v-model="form.DeviceId" filterable remote reserve-keyword default-first-option clearable
                        placeholder="请选择监测设备" size="medium" :remote-method="getDeviceList" :loading="getDeviceLoading"
                        style="width: 80%">
                        <el-option v-for="item in deviceSelect" :key="item.Id" :label="item.DeviceName" :value="item.Id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="推送方式" prop="SendType">
                    <el-select v-model="form.SendType" placeholder="请选择推送方式" size="medium" style="width: 80%">
                        <el-option label="tcp" value="0"> </el-option>
                        <el-option label="mqtt" value="1"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="版本号" prop="Version">
                    <el-input v-model="form.Version" size="medium" style="width: 80%"></el-input>
                </el-form-item>
                <el-form-item label="固件文件" prop="FileName">
                    <el-upload ref="firmwareFile" class="geology-single-file" :headers="dataHeader" :action="uploadurl"
                        :auto-upload="false" :show-file-list="true" :file-list="fileList" :limit="1" list-type="text"
                        :on-change="onFileUploadChange" :on-success="onFileUploadSuccess" :on-error="onFileUploadError">
                        <el-button size="small" type="primary">选择固件</el-button>
                    </el-upload>
                </el-form-item>
                <el-row>
                    <el-col :span="23" :offset="1">
                        <el-form-item>
                            <el-button type="primary" size="medium" @click="onSubmit">立即升级</el-button>
                            <el-button type="primary" plain size="medium" style="margin-left: 10px"
                                @click="dialogFormVisible = false">取消</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import {
    getDeviceList,
    getDeviceFirmwareList,
    addDeviceFirmware,
    delDeviceFirmware,
} from "@/api/device";
import { SingleFile } from "@/components/upload";
export default {
    data() {
        return {
            listData: [],
            tableLoading: false,

            filterDaterange: "",
            getDeviceLoading: false,
            deviceSelect: [],

            listfilter: {
                deviceid: "",
                fstarttime: "",
                fendtime: "",
            },
            page: {
                pagesize: 10,
                pageindex: 1,
                total: 0,
            },

            dialogFormVisible: false,
            form: {
                DeviceId: "",
                Version: "",
                FileName: "",
                SendType: "",
            },
            formRules: {
                DeviceId: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请选择灾害点",
                    },
                ],
                SendType: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请选择推送方式",
                    },
                ],
                Version: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入固件版本号",
                    },
                ],
                FileName: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请选择固件",
                    },
                ],
            },
            fileList: [],
            dataHeader: {
                auth: this.$store.getters.token,
            },
        };
    },
    components: {
        SingleFile,
    },
    computed: {
        uploadurl: function () {
            return (
                this.$extconfig.getConfig("apibase", "/") +
                "/api/Upload/FtpExportFile"
            );
        },
    },
    methods: {
        getDeviceList(value) {
            this.getDeviceLoading = true;
            getDeviceList(1, 10, {
                fname: value,
            }).then((resdata) => {
                this.getDeviceLoading = false;
                if (resdata.code == 0) {
                    this.deviceSelect = resdata.data.data;
                }
            });
        },
        dateChange(value) {
            if (value) {
                var newdate = [];
                for (
                    let index = 0;
                    index < this.filterDaterange.length;
                    index++
                ) {
                    const element = this.filterDaterange[index];
                    newdate.push(element);
                }
                this.listfilter.fstarttime = newdate[0];
                this.listfilter.fendtime = newdate[1];
                this.getListData();
            } else {
                this.listfilter.fstarttime = "";
                this.listfilter.fendtime = "";
                this.getListData();
            }
        },
        onSearch() {
            this.page.pageindex = 1;
            this.getListData();
        },
        onPageChange(pageindex) {
            this.page.pageindex = pageindex;
            this.getListData();
        },
        getListData() {
            this.tableLoading = true;
            getDeviceFirmwareList(
                this.page.pageindex,
                this.page.pagesize,
                this.listfilter
            )
                .then((resdata) => {
                    this.tableLoading = false;
                    if (resdata.code == 0) {
                        this.listData = resdata.data.data;
                        this.page.total = resdata.data.count * 1;
                    } else {
                        this.$message({
                            type: "error",
                            message: resdata.msg,
                        });
                    }
                })
                .catch(() => {
                    this.tableLoading = false;
                });
        },
        onListDel(item) {
            this.$confirm("确定要删除选择的数据吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    delDeviceFirmware(item.Id)
                        .then((res) => {
                            if (res.code == 0) {
                                this.$message.success("删除成功!");
                                this.getListData();
                            } else {
                                this.$message.error("删除失败!" + res.msg);
                            }
                        })
                        .catch((errmsg) => {
                            this.$message.error(errmsg);
                        });
                })
                .catch(() => { });
        },
        onAdd() {
            this.form = {
                DeviceId: "",
                Version: "",
                FileName: "",
                SendType: "",
            };
            this.dialogFormVisible = true;
        },
        onFileUploadChange(file) {
            console.log(file);
            this.form.FileName = file.name;
        },
        onFileUploadSuccess(response) {
            console.log(response);
            if (response.code == 0) {
                var result = response.data;
                var postdata = JSON.parse(JSON.stringify(this.form));
                postdata.FileName = result.name;

                this.$refs.firmwareFile.clearFiles();
                this.form.FileName = "";

                addDeviceFirmware(postdata)
                    .then((res) => {
                        if (res.code == 0) {
                            this.dialogFormVisible = false;
                            this.$message.success("保存成功!");
                            this.getListData();
                        } else {
                            this.$message.error("保存失败!" + res.msg);
                        }
                    })
                    .catch((errmsg) => {
                        this.$message.error("保存失败!" + errmsg);
                    });
            } else {
                this.$message.error("上传文件失败");
            }
        },
        onFileUploadError() { },
        onSubmit() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.$refs.firmwareFile.submit();
                } else {
                    return false;
                }
            });
        },
    },
    created() {
        this.getDeviceList();
        this.getListData();
    },
};
</script>